/* Desktop */

#root { 
    width: 100%; 
    height: 100%; 
    display: flex; 
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.App {
    width: 80%;
    max-width: 800px;
    margin: 10px auto;
    padding: 10px 20px;
    background-color: #fafafa;
    box-shadow: 0 0 5px #ccc;
    border-radius: 10px;
    text-align: center;
}

h1 {
    font-size: 1.5rem;
    margin-bottom: 20px;
}

h2 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

.instructions {
    font-style: italic;
}

#ingredient-form-container {
    color: #9a66db;  /* purple */
}

#ingredient-form {
    margin-bottom: 20px;
}

#ingredient-input {
    font-size: 1.2rem;
    padding: 5px;
    margin-right: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
}

.button {
    font-size: 1.2rem;
    padding: 5px 10px;
    background-color: #0071bc;  /* blue */
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.button:hover {
    background-color: #0096e6;
}

#ingredient-list {
    margin-bottom: 20px;
    list-style-type: none;
    padding: 0;
}

.chip-highlighted, .chip-deselected {
    font-size: 1.2rem;
    margin: 4px;
    display: inline-block;
    padding: 4px 12px;
    border-radius: 12px;
    cursor: pointer;
}

.chip-highlighted {
    background-color: #a4ea95;  /* light green */
}

.chip-deselected {
    background-color: #EEE;  /* light gray */
}

#ingredient-list li span {
    font-size: 1.2rem;
    font-weight: bold;
    margin-left: 10px;
}

#ingredient-list .delete-icon {
    display: inline;
    color: #f00;
    font-weight: bold;
    padding-left: 10px;
    cursor: pointer;
}

#recipes-placeholder {
  color: #9999CC;
}

#recipe-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    list-style-type: none;
     padding: 0;
}

#recipe-list li {
    width: 100%;  /* 33% */
    margin-bottom: 10px;
    text-align: center;
    padding: 10px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.recipe-image-container {
  position: relative;
  display: inline-block;    
}

.recipe-hostname-overlay {
  position: absolute;
  line-height: 16px;
  bottom: 16px;
  right: 0;
  padding: 2px;
  background: rgba(0, 0, 0, 0.5);
  color: #fff;
  font-size: 12px;
  pointer-events: none;
}

@media screen and (min-width: 840px) {
    /* On desktop: 3-column layout*/
    #recipe-list li {
        width: 33%;
    }
}

#recipe-list li a {
    text-decoration: none;
    color: #0071bc;  /* blue */
}

#recipe-list h3 {
    font-size: 1.2rem;
    margin-bottom: 10px;
}

#recipe-list img {
    width: 200px;
    margin-bottom: 10px;
}

#recipe-list p {
    font-size: 1.2rem;
    margin-bottom: 5px;
}

.used-ingredients {
    font-weight: bold;
    color: #4caf50;  /* green */
}

.missed-ingredients {
    font-style: italic;
    color: #999;  /* gray */
}

.recipe-details {
    font-size: smaller;
    font-weight: bold;
    color: #89F; /* blue */
}

.search-settings-button {
    cursor: pointer;
    color: #89F; /* blue */
    font-size: smaller;
    font-weight: bold;
}

#max-recipe-time-input {
    width: 52px;
    margin: 0 4px;
    text-align:center;
}

.filter {
    background-color: #e1e8e5;  /* greenish gray */
    border-radius: 8px;
    padding: 8px;
    margin: 8px 0;
}

.selected-or-all-label {
    font-size: 1.2rem;
    font-weight: bold;
    margin: 4px;
}